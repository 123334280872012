import { Grid, Group } from '@mantine/core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import Collection from '../../components/Collection';
import ErrorBoundary from '../../components/ErrorBoundary';
import type CollectionType from '../../models/Collection';
import type Txn from '../../models/Txn';
import styles from './styles.module.css';

type Props = {
  loading: boolean;
  walletNfts: Txn[];
  walletAddress: string;
  collections: CollectionType[];
};

const NftCollectionsSelector = ({ walletAddress, collections, walletNfts, loading }: Props) => {
  const navigate = useNavigate();

  const onSelectCollection = useCallback(
    (contractAddress: string) => navigate(`./${contractAddress}`),
    [navigate]
  );

  return (
    <section className={styles.container}>
      {walletNfts.length > 0 ? (
        <>
          <Group px="lg" mb="md" className={styles.titleContainer}>
            <h4>Select NFT Collection</h4>
          </Group>
          <Grid>
            {collections.map((collection) => (
              <Grid.Col span={12} key={collection.contractAddress}>
                <ErrorBoundary componentName={collection.name}>
                  <Collection
                    maxNfts={6}
                    collection={collection}
                    walletAddress={walletAddress}
                    onSelectCollection={onSelectCollection}
                    walletNfts={walletNfts}
                  />
                </ErrorBoundary>
              </Grid.Col>
            ))}
          </Grid>
        </>
      ) : !loading ? (
        <Group position="center" px="lg" mb="md" className={styles.titleContainer}>
          <h4>No NFTs found for this selected wallet</h4>
        </Group>
      ) : (
        <></>
      )}
    </section>
  );
};

export default NftCollectionsSelector;
