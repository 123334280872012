import { useParams } from "react-router";
import { useEffect, useState } from "react";
import type CollectionType from '../models/Collection';

const useParamsCollection = (collections: CollectionType[]) => {
  const [currentCollection, setCurrentCollection] = useState<CollectionType | null>(null);
  const { collectionAddress } = useParams();

  useEffect(() => {
    if (collections.length && collectionAddress) {
      const collection = collections.find((coll) => coll.contractAddress === collectionAddress);
      if (!collection) {
        console.error(`Collection ${collectionAddress} not found`);
        return;
      }
      setCurrentCollection(collection);
    }
  }, [collections, collectionAddress]);

  return currentCollection;
};

export default useParamsCollection;
