import { createContext } from "react";
import type NFT from "../models/NFT";

type State = {
  nftsMap: Map<string, NFT>;
};

export const TOGGLE_NFT = 'TOGGLE_NFT';
export const RESET_SELECTION = 'RESET_SELECTION';

export const selectedNftsInitialState: State = {
  nftsMap: new Map<string, NFT>(),
}

export const selectedNftsReducer = (state: State, action: any) => {
  switch (action.type) {
    case TOGGLE_NFT:
      const nftsMap = new Map(state.nftsMap);
      const token: NFT = action.payload;
      if (nftsMap.has(token.id)) {
        nftsMap.delete(token.id);
      } else {
        nftsMap.set(token.id, token);
      }
      return { ...state, nftsMap };

    case RESET_SELECTION:
      return { ...state, nftsMap: new Map<string, NFT>() };

    default:
      return state;
  }
}

export const SelectedNftsContext = createContext<any>([]);
