import { Box, Button, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import LogoSvg from '../../assets/logo.svg';
import styles from './styles.module.css';

const WalletSelection = ({ onConnectWallet }: { onConnectWallet: any }) => {
  const [metaMaskInstalled, setMetaMaskInstalled] = useState(true);

  useEffect(() => {
    if (!window.ethereum) {
      setMetaMaskInstalled(false);
    }
  }, []);

  return (
    <section className={styles.container}>
      <img src={LogoSvg} alt="logo" width="200" height="200" />
      <Box className={styles.actionContainer}>
        <Text align="center" size="lg" mt="xs" mb="xl" weight="bold" className={styles.title}>
          NFT Bulk Sender
        </Text>
        <Button
          size="lg"
          radius="md"
          variant="gradient"
          gradient={{ from: 'teal', to: 'blue', deg: 60 }}
          className={styles.button}
          onClick={onConnectWallet}
        >
          Connect Wallet
        </Button>

        {!metaMaskInstalled && (
          <Text align="center" size="sm" mt="lg" weight="bold" className={styles.disclaimer}>
            Please install{' '}
            <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
              MetaMask
            </a>{' '}
            to use this app
          </Text>
        )}
      </Box>
    </section>
  );
};

export default WalletSelection;
