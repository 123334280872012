import cx from 'classnames';
import { useCallback, useEffect } from 'react';
import { CircleCheck, Click } from 'tabler-icons-react';
import { ActionIcon, Box, Button, Grid, Group, Paper, Text } from '@mantine/core';
import type NFT from '../../models/NFT';
import type Txn from '../../models/Txn';
import type CollectionType from '../../models/Collection';
import useCollectionNfts from '../../hooks/useCollectionNfts';
import styles from './styles.module.css';

type Props = {
  walletNfts: Txn[];
  maxNfts?: number;
  preview?: boolean;
  walletAddress: string;
  collection: CollectionType;
  selectedNfts?: Map<string, NFT>;
  onSelectNft?: (nft: NFT) => void;
  onSelectCollection?: (contractAddress: string) => void;
};

const Collection = ({
  preview,
  maxNfts,
  collection,
  walletAddress,
  onSelectCollection,
  onSelectNft,
  selectedNfts,
  walletNfts,
}: Props) => {
  const {
    nfts,
    refreshNfts,
    currentWalletAddress,
    loading: nftsLoading,
  } = useCollectionNfts(collection.contractAddress, walletAddress, walletNfts, maxNfts);

  const isCollectionSelectionEnabled = !!onSelectCollection;

  const onClickCollection = useCallback(() => {
    if (!nftsLoading && onSelectCollection) {
      onSelectCollection(collection.contractAddress);
    }
  }, [collection, nftsLoading, onSelectCollection]);

  const onClickNft = useCallback(
    (index: number) => {
      if (!onSelectCollection && onSelectNft) {
        onSelectNft(nfts[index]);
      }
    },
    [nfts, onSelectNft, onSelectCollection]
  );

  useEffect(() => {
    if (!nftsLoading && walletAddress !== currentWalletAddress) {
      refreshNfts(walletAddress);
    }
  }, [nftsLoading, refreshNfts, currentWalletAddress, walletAddress]);

  return (
    <Box className={styles.container} px="lg" py="xl">
      <Group position="apart" mb="md">
        <Text size="lg" weight="bold">
          {collection.name}
        </Text>
        {isCollectionSelectionEnabled && (
          <Button
            variant="gradient"
            loading={nftsLoading}
            loaderPosition="right"
            onClick={onClickCollection}
            gradient={{ from: 'teal', to: 'blue', deg: 60 }}
            rightIcon={<Click />}
          >
            Select this collection
          </Button>
        )}
      </Group>
      {!nftsLoading && isCollectionSelectionEnabled && (
        <div className={styles.overlay} onClick={onClickCollection}>
          <ActionIcon size="xl" radius="xl" variant="default">
            <Click />
          </ActionIcon>
        </div>
      )}
      {!nftsLoading ? (
        <div className={styles.gridWrapper}>
          <Grid>
            {nfts.slice(0, maxNfts).map(
              (nft, i) =>
                !(!selectedNfts?.has(nft.id) && preview) && (
                  <Grid.Col span={2} key={nft.id}>
                    <div className={styles.cardWrapper}>
                      {selectedNfts?.has(nft.id) && <CircleCheck size={32} />}
                      <Paper
                        p="md"
                        shadow="xs"
                        withBorder
                        className={cx(
                          styles.nftCard,
                          preview && styles.preview,
                          selectedNfts?.has(nft.id) && styles.selected
                        )}
                        onClick={() => onClickNft(i)}
                      >
                        <object data={nft?.metadata?.image} type="image/png" width="100">
                          <img
                            src={require('../../assets/images/empty.jpeg')}
                            alt={(nft?.metadata?.name ?? '').slice(0, 20)}
                            loading="lazy"
                            width="100"
                          />
                        </object>
                        <Text mt="sm">
                          {nft?.metadata?.name ?? ''}
                          {nft?.metadata?.name?.includes('#') ? '' : ` #${nft.id || 0}`}
                        </Text>
                      </Paper>
                    </div>
                  </Grid.Col>
                )
            )}
          </Grid>
        </div>
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  );
};

export default Collection;
