import NFTContract from '../contracts/NFT.json';
import bulkSenderContract from '../contracts/BulkNFTSender.json';
import ContractInstance from '../models/ContractInstance';

const fetchContractInstance = (contractAddress: string): ContractInstance => {
  let contractABI = '';

  if (contractAddress === process.env.REACT_APP_BULK_SENDER_CONTRACT) {
    contractABI = JSON.stringify(bulkSenderContract.abi);
  } else {
    contractABI = JSON.stringify(NFTContract.abi);
  }

  const { Contract } = window.web3Client.eth;
  const contractInstance = new Contract(JSON.parse(contractABI), contractAddress);
  return contractInstance;
}

export default fetchContractInstance;
