import Txn from '../models/Txn';

const generateUrl = (walletAddress: string, cursor = '', limit = 100) =>
  `https://deep-index.moralis.io/api/v2/${walletAddress}/nft?chain=avalanche${
    process.env.NODE_ENV === 'development' ? '%20testnet' : ''
  }&format=decimal&limit=${limit}&cursor=${cursor}`;

const getTokenUri = (tokenUri: string) => {
  if (tokenUri.includes('/ipfs/')) {
    return `https://ipfs.infura.io/ipfs/${tokenUri.split('/ipfs/')[1]}`;
  } else if (tokenUri.startsWith('ipfs://')) {
    return `https://ipfs.infura.io/ipfs/${tokenUri.split('ipfs://')[1]}`;
  }
  return tokenUri;
};

const fetchWalletNfts = async (
  walletAddress: string,
  cursor?: string,
  count = 0
): Promise<Txn[]> => {
  const url = generateUrl(walletAddress, cursor);
  let response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY || '',
    },
  });

  const data = await response.json();
  const dataResult = data.result;

  try {
    const finalResult = dataResult.map((txn: Txn) => ({
      ...txn,
      token_uri: getTokenUri(txn.token_uri || ''),
    }));

    if (data.cursor && count < 10) {
      return [...finalResult, ...(await fetchWalletNfts(walletAddress, data.cursor, count + 1))];
    }

    return finalResult;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default fetchWalletNfts;
