import { useCallback, useContext, useEffect, useState } from 'react';
import { ArrowBack } from 'tabler-icons-react';
import { Box, Button, Group, Input, Stepper, Text } from '@mantine/core';
import type ContractInstance from '../../models/ContractInstance';
import type CollectionType from '../../models/Collection';
import type NFT from '../../models/NFT';
import type Txn from '../../models/Txn';
import Collection from '../../components/Collection';
import useParamsCollection from '../../hooks/useParamsCollection';
import fetchContractInstance from '../../utils/fetchContractInstance';
import { RESET_SELECTION, SelectedNftsContext } from '../../utils/selectedNfts.reducer';
import styles from './styles.module.css';

const bulkSenderAddress = process.env.REACT_APP_BULK_SENDER_CONTRACT || '0x0';

type Props = {
  walletNfts: Txn[];
  walletAddress: string;
  collections: CollectionType[];
};

const ApplyTransaction = ({ walletAddress, collections, walletNfts }: Props) => {
  const [active, setActive] = useState(0);
  const [initialWalletAddress] = useState(walletAddress);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const [selectedNftsState, dispatch] = useContext(SelectedNftsContext);
  const [selectedNfts, setSelectedNfts] = useState<NFT[]>([]);
  const [recipientAddress, setRecipientAddress] = useState('');
  const [bulkSendLoading, setBulkSendLoading] = useState(false);
  const [allowContractLoading, setAllowContractLoading] = useState(false);
  const [collectionInstance, setCollectionInstance] = useState<ContractInstance>();
  const [bulkSenderInstance, setBulkSenderInstance] = useState<ContractInstance>();
  const collection = useParamsCollection(collections);

  const reset = useCallback(() => {
    dispatch({ type: RESET_SELECTION });
    window.location.href = '/';
  }, [dispatch]);

  useEffect(() => {
    if (!collection) {
      return;
    }

    setCollectionInstance(fetchContractInstance(collection.contractAddress));
    setBulkSenderInstance(fetchContractInstance(bulkSenderAddress));
  }, [collection]);

  useEffect(() => {
    if (selectedNftsState?.nftsMap) {
      const preSelectedNfts = Array.from<NFT>(selectedNftsState.nftsMap.values());
      setSelectedNfts(preSelectedNfts);

      if (!preSelectedNfts.length) {
        reset();
      }
    }
  }, [selectedNftsState, reset]);

  useEffect(() => {
    if (walletAddress !== initialWalletAddress) {
      reset();
    }
  }, [reset, walletAddress, initialWalletAddress]);

  return (
    <section className={styles.container}>
      <Group px="md">
        <h3>Verify your Transaction</h3>
      </Group>
      <Stepper color="teal" active={active} breakpoint="sm" px="md">
        <Stepper.Step
          label={
            <Text color="teal" weight="bold">
              First step
            </Text>
          }
          description="Recipient address"
        >
          <Box mt="md" pt="md">
            <Text pb="sm">Please specify recipient address</Text>
            <Input
              variant="default"
              placeholder="Recipient address"
              onChange={(e: any) => setRecipientAddress(e.target.value)}
              value={recipientAddress}
            />
          </Box>
        </Stepper.Step>
        <Stepper.Step
          label={
            <Text color="teal" weight="bold">
              Second step
            </Text>
          }
          description="Allow contract"
        />
        <Stepper.Step
          label={
            <Text color="teal" weight="bold">
              Final step
            </Text>
          }
          description="Initiate transaction"
        />
        <Stepper.Completed>
          <Text mt="md" pt="md" align="center" weight="bold" size="xl">
            Completed, your transaction should be processed soon!
          </Text>
        </Stepper.Completed>
      </Stepper>
      <Group position="center" mt="xl" pt="xl">
        {active > 0 && (
          <Button
            size="lg"
            color="teal"
            variant="subtle"
            onClick={active < 3 ? prevStep : reset}
            leftIcon={<ArrowBack />}
          >
            Back
          </Button>
        )}
        {active === 0 && (
          <Button
            size="lg"
            variant="gradient"
            gradient={{ from: 'teal', to: 'blue', deg: 60 }}
            onClick={() => {
              if (!collectionInstance || !bulkSenderInstance) {
                alert('Contract not connected!');
                return;
              }
              if (!recipientAddress) {
                alert('Please specify recipient address');
                return;
              }
              if (recipientAddress.match(/^0x[a-fA-F0-9]{40}$/) === null) {
                alert('Make sure recipient address is a valid Avalanche address');
                return;
              }
              nextStep();
            }}
          >
            Next step
          </Button>
        )}
        {active === 1 && (
          <Button
            size="lg"
            variant="gradient"
            gradient={{ from: 'teal', to: 'blue', deg: 60 }}
            loading={allowContractLoading}
            onClick={async () => {
              setAllowContractLoading(true);
              const { setApprovalForAll } = collectionInstance!.methods;
              await setApprovalForAll(bulkSenderAddress, true).send({ from: walletAddress });
              setAllowContractLoading(false);
              nextStep();
            }}
          >
            Allow contract
          </Button>
        )}
        {active === 2 && (
          <Button
            size="lg"
            variant="gradient"
            gradient={{ from: 'teal', to: 'blue', deg: 60 }}
            loading={bulkSendLoading}
            onClick={async () => {
              setBulkSendLoading(true);
              const address = collection!.contractAddress;
              const { bulkSend } = bulkSenderInstance!.methods;
              await bulkSend(
                address,
                recipientAddress,
                selectedNfts.map((nft) => nft.id)
              ).send({ from: walletAddress });
              setBulkSendLoading(false);
              nextStep();
            }}
          >
            Initiate transaction
          </Button>
        )}
      </Group>
      {recipientAddress && active > 0 && (
        <Box pl="lg" mt="xl" pt="xl">
          <Text mb="md">
            <Text weight="bold">Note:</Text>
            <Text>
              You will be sending the following NFTs to: <b>{recipientAddress}</b>
            </Text>
          </Text>
        </Box>
      )}
      <Box mt="sm" pt="sm">
        {collection && (
          <Collection
            preview
            collection={collection}
            walletAddress={walletAddress}
            selectedNfts={selectedNftsState.nftsMap}
            walletNfts={walletNfts}
          />
        )}
      </Box>
    </section>
  );
};

export default ApplyTransaction;
