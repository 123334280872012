import { Box, Text } from '@mantine/core';
import React from 'react';

type State = {
  hasError: boolean;
};

type Props = {
  componentName?: string;
  children: React.ReactNode;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box pl="md">
          <Text size="lg" style={{ border: '1px solid black', display: 'inline-block' }}>
            Error loading {this.props.componentName}
          </Text>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
