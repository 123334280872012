import { useCallback, useEffect, useState } from "react";
import type NFT from "../models/NFT";
import type Txn from "../models/Txn";
import fetchContractInstance from "../utils/fetchContractInstance";

const useCollectionNfts = (collectionAddress: string, walletAddress: string, nftTxns: Txn[], maxNfts: number = 0) => {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentWalletAddress, setCurrentWalletAddress] = useState(walletAddress);

  const populateNfts = useCallback(async (currentWalletAddress: string) => {
    if (!collectionAddress || !currentWalletAddress) {
      console.error("Missing collectionAddress or currentWalletAddress");
      return;
    }

    const contractInstance = fetchContractInstance(collectionAddress);

    let collNfts = nftTxns.filter((el) => el.token_address === collectionAddress);

    if (maxNfts) {
      collNfts = collNfts.slice(0, maxNfts);
    }

    const nftWithMetaData = await Promise.all(
      collNfts.map(async (txn) => {
        let metadata = {
          name: '',
          description: '',
          id: txn.token_id,
          image: '',
        };

        try {
          if (txn.metadata) {
            metadata = JSON.parse(txn.metadata);
          } else {
            if (txn.token_uri) {
              metadata = await fetch(txn.token_uri).then((res) => res.json());
            } else {
              let tokenUri = await contractInstance.methods.tokenURI(txn.token_id).call();
              if (tokenUri) {
                if (tokenUri.startsWith('ipfs://')) {
                  tokenUri = tokenUri.replace('ipfs://', 'https://ipfs.infura.io/ipfs/');
                }
                metadata = await fetch(tokenUri).then((res) => res.json());
              }
            }
          }
        } catch (error) {
          console.error(error);
        }

        if (metadata.image && metadata.image.startsWith('ipfs://')) {
          metadata.image = `https://ipfs.infura.io/ipfs/${metadata.image.split('ipfs://')[1]}`;
        }

        if (metadata.name) {
          metadata.name = `${(metadata?.name || '')}`.slice(0, 50);
        } else {
          metadata.name = '';
        }

        return { url: txn.token_uri, metadata, id: txn.token_id };
      })
    );

    setCurrentWalletAddress(currentWalletAddress);
    setNfts(nftWithMetaData);
    setLoading(false);
  }, [collectionAddress, nftTxns, maxNfts]);

  const refreshNfts = useCallback(async (newWalletAddress: string) => {
    setNfts([]);
    setLoading(true);
    populateNfts(newWalletAddress);
  }, [populateNfts]);

  useEffect(() => {
    try {
      populateNfts(walletAddress);
    } catch (error) {
      setLoading(false);
      console.error(
        'Something went wrong:',
        process.env.NODE_ENV !== 'production' ? error : {},
      );
    }
  }, [populateNfts, walletAddress]);

  return { nfts, currentWalletAddress, refreshNfts, loading };
};

export default useCollectionNfts;
