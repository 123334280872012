import type Collection from "../models/Collection";
import type Txn from "../models/Txn";

const getCollectionRank = (txn: Txn) => {
  if (txn.name === 'Soldier Armies') {
    console.log(txn.metadata);
  }

  if (txn.symbol === 'AVAS') {
    return 1e6
  }

  try {
    if (txn.metadata) {
      let metadata = JSON.parse(txn.metadata);
      if (typeof metadata.image === 'string') {
        return metadata.image.includes('pinata.cloud') ? 0 : 1e4;
      } else {
        return 0;
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
}

const fetchWalletCollections = (nfts: Txn[]) => {
  const collectionsMap = new Map<string, Collection>();
  nfts.forEach(txn => {
    const coll = collectionsMap.get(txn.token_address);
    if (coll) {
      collectionsMap.set(txn.token_address, {
        name: txn.name,
        symbol: txn.symbol,
        contractAddress: txn.token_address,
        nftsCount: coll.nftsCount + 1,
        rank: getCollectionRank(txn),
      });
    } else {
      collectionsMap.set(txn.token_address, {
        name: txn.name,
        symbol: txn.symbol,
        contractAddress: txn.token_address,
        nftsCount: 1,
        rank: getCollectionRank(txn),
      });
    }
  });

  const collections = Array.from(collectionsMap.values());
  return collections.filter(coll => coll.name).sort((a, b) => (b.rank + b.nftsCount) - (a.rank + a.nftsCount));
};

export default fetchWalletCollections;
