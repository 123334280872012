import { Button, Group } from '@mantine/core';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Send } from 'tabler-icons-react';
import type NFT from '../../models/NFT';
import type Txn from '../../models/Txn';
import type CollectionType from '../../models/Collection';
import { SelectedNftsContext, TOGGLE_NFT } from '../../utils/selectedNfts.reducer';
import useParamsCollection from '../../hooks/useParamsCollection';
import Collection from '../../components/Collection';
import styles from './styles.module.css';

type Props = {
  walletNfts: Txn[];
  walletAddress: string;
  collections: CollectionType[];
};

const NftAssetsSelector = ({ walletAddress, collections, walletNfts }: Props) => {
  const [state, dispatch] = useContext(SelectedNftsContext);
  const collection = useParamsCollection(collections);
  const navigate = useNavigate();

  const onSelectNft = useCallback(
    (nft: NFT) => {
      dispatch({ type: TOGGLE_NFT, payload: nft });
    },
    [dispatch]
  );

  const navigateToTransaction = useCallback(() => {
    const selectedNfts = Array.from(state.nftsMap.values());
    if (selectedNfts.length) {
      navigate('./send');
    } else {
      alert('Please select at least one NFT');
    }
  }, [state, navigate]);

  return (
    <section className={styles.container}>
      <Group position="apart" px="lg" mb="md" className={styles.titleContainer}>
        <h4>Select the NFTs to send</h4>
        <Button
          size="md"
          variant="gradient"
          rightIcon={<Send />}
          gradient={{ from: 'teal', to: 'blue', deg: 60 }}
          onClick={navigateToTransaction}
        >
          Send Selected NFTs
        </Button>
      </Group>
      {collection && (
        <Collection
          collection={collection}
          walletAddress={walletAddress}
          selectedNfts={state.nftsMap}
          onSelectNft={onSelectNft}
          walletNfts={walletNfts}
        />
      )}
    </section>
  );
};

export default NftAssetsSelector;
