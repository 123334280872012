import Web3 from 'web3';
import { Modal, Text } from '@mantine/core';
import { Navigate, Route, Routes } from 'react-router';
import { useEffect, useReducer, useState } from 'react';
import WalletSelection from './pages/WalletSelection';
import ApplyTransaction from './pages/ApplyTransaction';
import NftAssetsSelector from './pages/NftAssetsSelector';
import NftCollectionsSelector from './pages/NftCollectionsSelector';
import fetchWalletCollections from './utils/fetchWalletCollections';
import {
  selectedNftsInitialState,
  selectedNftsReducer,
  SelectedNftsContext,
} from './utils/selectedNfts.reducer';
import type Txn from './models/Txn';
import type CollectionType from './models/Collection';
import fetchWalletNfts from './utils/fetchWalletNfts';

const App = () => {
  const [selectedNftsState, selectedNftsDispatch] = useReducer(
    selectedNftsReducer,
    selectedNftsInitialState
  );

  const [nftsLoading, setNftsLoading] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const [walletNfts, setWalletNfts] = useState<Txn[]>([]);
  const [collections, setCollections] = useState<CollectionType[]>([]);
  const [currentAccount, setCurrentAccount] = useState<string | null>(null);

  const checkNetwork = async () => {
    let isProduction = process.env.NODE_ENV === 'production';
    const expectedChainId = isProduction ? 43114 : 43113;
    const chainId = await window.web3Client.eth.getChainId();
    if (chainId !== expectedChainId) {
      alert(
        `Make sure you have selected the Avalanche ${
          isProduction ? 'Mainnet' : 'Fuji'
        } C-Chain network from MetaMask.`
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    const connectWeb3 = async () => {
      if (!Web3.givenProvider) {
        return;
      }

      const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');
      window.web3Client = web3;

      if (await checkNetwork()) {
        const accounts = await web3.eth.getAccounts();
        setCurrentAccount(accounts[0]);
      }
    };

    connectWeb3();

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async () => {
        const accounts = await window.web3Client.eth.getAccounts();
        setCurrentAccount(accounts[0]);
        setWalletNfts([]);
      });

      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    if (window.location.search.includes('reload=true')) {
      setWalletNfts([]);
    }
    const init = async () => {
      if (currentAccount) {
        const nfts = await fetchWalletNfts(currentAccount);
        setCollections(fetchWalletCollections(nfts));
        setWalletNfts(nfts);
        setTimeout(() => {
          setNftsLoading(false);
        }, 150);
      }
    };
    init();
  }, [currentAccount]);

  if (!currentAccount) {
    return (
      <main>
        <WalletSelection
          onConnectWallet={async () => {
            if (!window.web3Client) {
              setModalOpened(true);
            } else {
              if (await checkNetwork()) {
                window.web3Client.eth.requestAccounts();
              }
            }
          }}
        />
        <Modal opened={modalOpened} onClose={() => setModalOpened(false)} title="Install MetaMask">
          <img
            style={{ margin: 'auto', display: 'block' }}
            src={require('./assets/images/metamask.png')}
            alt="metamask"
            width="100"
            height="100"
          />
          <Text size="sm" mt="md" mb="sm" weight="bold" align="center">
            Please install{' '}
            <a
              href="https://metamask.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#e27624' }}
            >
              MetaMask
            </a>{' '}
            to use this app
          </Text>
        </Modal>
      </main>
    );
  }

  return (
    <main>
      <SelectedNftsContext.Provider value={[selectedNftsState, selectedNftsDispatch]}>
        <Routes>
          <Route
            path="collections"
            element={
              <NftCollectionsSelector
                walletAddress={currentAccount}
                collections={collections}
                walletNfts={walletNfts}
                loading={nftsLoading}
              />
            }
          />
          <Route
            path="collections/:collectionAddress"
            element={
              <NftAssetsSelector
                walletAddress={currentAccount}
                collections={collections}
                walletNfts={walletNfts}
              />
            }
          />
          <Route
            path="collections/:collectionAddress/send"
            element={
              <ApplyTransaction
                walletAddress={currentAccount}
                collections={collections}
                walletNfts={walletNfts}
              />
            }
          />
          <Route path="*" element={<Navigate to="/collections" replace />} />
        </Routes>
      </SelectedNftsContext.Provider>
    </main>
  );
};

export default App;
